"use client"

import { Tenant } from "@deltagreen/proteus-database"
import { createContext, PropsWithChildren, useContext } from "react"

type TenantContextData = {
  tenantId: string
  tenant: Tenant
  domains: { id: string; fqdn: string }[]
}

const TenantContext = createContext<TenantContextData | null>(null)

export function useTenant() {
  const tenant = useContext(TenantContext)
  if (!tenant) {
    throw new Error("tenant not found in context")
  }

  return tenant
}

type Props = {
  tenant: TenantContextData
}

export function TenantProvider(props: PropsWithChildren<Props>) {
  const { children, tenant } = props
  return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>
}
