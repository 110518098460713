export const LINKS = {
  home: "/",
  authLogin: "/auth/login",
  authVerifyRequest: "/auth/verify-request",
  authMagicLinkInvalid: (reason?: "invalidToken" | "tokenExpired") =>
    `/auth/magic-link-invalid${reason ? `?reason=${reason}` : ""}`,
  authMagicCallback: "/auth/magic/callback",

  setupDevices: "/setup/devices",

  household: (id: string) => `/household/${id}`,
  householdSetup: (id: string) => `/household/${id}/setup`,

  householdAddInverter: (id: string) => `/household/${id}/add-device/inverter`,
  householdAddInverterModel: (id: string, model: string) => `/household/${id}/add-device/inverter/${model}`,
  householdAddInverterModelTerms: (id: string, model: string) => `/household/${id}/add-device/inverter/${model}/terms`,
  householdAddInverterSolaxApi: (id: string) => `/household/${id}/add-device/inverter/solax/api`,
  householdAddInverterSolaxDeltaLink: (id: string) => `/household/${id}/add-device/inverter/solax/delta-link`,

  householdAddTesla: (id: string) => `/household/${id}/add-device/electric-vehicle/tesla`,
  householdAddTeslaSelectVehicle: (householdId: string, householdAccountId: string) =>
    `/household/${householdId}/add-device/electric-vehicle/tesla/${householdAccountId}/select-vehicle`,

  deviceInverter: (id: string) => `/device/inverter/${id}`,
  deviceInverterFaq: (id: string) => `/device/inverter/${id}/faq`,
  deviceInverterPilot: (id: string) => `/device/inverter/${id}/pilot`,
  deviceInverterContact: (id: string) => `/device/inverter/${id}/contact`,
  deviceInverterStats: (id: string) => `/device/inverter/${id}/stats`,
  deviceInverterSettings: (id: string) => `/device/inverter/${id}/settings`,
  deviceInverterSettingsTerms: (id: string) => `/device/inverter/${id}/settings/terms`,
  deviceInverterPlan: (id: string) => `/device/inverter/${id}/plan`,
  deviceInverterPlanPredictions: (id: string, controlPlanId: string) =>
    `/device/inverter/${id}/plan/${controlPlanId}/predictions`,
  deviceInverterPlanVisualizations: (id: string, controlPlanId: string) =>
    `/device/inverter/${id}/plan/${controlPlanId}/predictions`,
  deviceInverterStep: (inverterId: string, stepId: string) => `/device/inverter/${inverterId}/plan/step/${stepId}`,
  deviceInverterTerms: (id: string, controlMode: "automatic" | "flexibility") =>
    `/device/inverter/${id}/terms?controlMode=${controlMode}`,

  deviceElectricVehicle: (electricVehicleId: string) => `/device/electric-vehicle/${electricVehicleId}`,
  deviceElectricVehicleStats: (electricVehicleId: string) => `/device/electric-vehicle/${electricVehicleId}/stats`,
  deviceElectricVehicleHistory: (electricVehicleId: string) => `/device/electric-vehicle/${electricVehicleId}/history`,
  deviceElectricVehicleSettings: (electricVehicleId: string) =>
    `/device/electric-vehicle/${electricVehicleId}/settings`,
  deviceElectricVehicleTeslaPairPublicKey: (electricVehicleId: string) =>
    `/device/electric-vehicle/${electricVehicleId}/tesla/pair-public-key`,
}

export const API_ROUTES = {
  authMagicLogin: "/api/auth/magic/login",
  authMagicCallback: "/api/auth/magic/callback",
  authLogout: "/api/auth/logout",
  authImpersonate: "/api/auth/impersonate",
  authRemotePortal: "/api/auth/remote/portal",
  authLoginDev: "/api/dev/login",
}

export const EXTERNAL_LINKS = {
  vrmSettings: (id: string) => `https://vrm.victronenergy.com/installation/${id}/settings/users`,
  vrmPortalLogin: "https://vrm.victronenergy.com/login",
  solaxCloud: `https://solaxcloud.com/`,
  solaxCloudLogin: `https://www.solaxcloud.com/#/login`,
  solaxCloudRegister: `https://www.solaxcloud.com/#/register`,

  webProduction: "https://www.deltagreen.cz/jen-vyrabim",
  webConsumption: "https://www.deltagreen.cz/jen-spotrebovavam",
  webProductionAndConsumption: "https://www.deltagreen.cz/vyrabim-a-spotrebovavam",
  webSpot: "https://moje.deltagreen.cz/spot",
  webContact: "https://www.deltagreen.cz/kontakt",

  deltaLinkConnectGuide: "https://www.deltagreen.cz/delta-link",
  deltaLinkStoreSolax: "https://eshop.deltagreen.cz/delta-link-pro-solax/",
  deltaLinkStoreGoodwe: "https://eshop.deltagreen.cz/delta-link-pro-goodwe/",

  infigyPortalLogin: "https://app.infigy.cz",
  egdDistributionPortal: "https://www.egd.cz/portal-distribuce24",
}
